<template>
  <chart-line-statistics
    :chart-data="chartData"
    :options="options"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import * as types from '@/store/types';

import ChartLineStatistics from './ChartLineStatistics';

export default {
  name: 'ChartStatisticsConsultations',
  components: {
    ChartLineStatistics,
  },
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          mode: 'x',
          bodySpacing: 10,
        },
        legend: {
          align: 'start',
          labels: {
            fontFamily: 'Montserrat',
            fontSize: 14,
            usePointStyle: true,
            padding: 30,
          },
        },
        scales: {
          xAxes: [{
            gridLines: {
              display: false,
              color: '#CCC8BC',
              lineWidth: 3,
              zeroLineWidth: 3,
              zeroLineColor: '#dadada',
              drawTicks: true,
              tickMarkLength: 3,
            },
            ticks: {
              fontFamily: 'Montserrat',
              fontSize: 14,
              fontColor: '#908F8D',
            },
          }],
          yAxes: [{
            gridLines: {
              display: true,
              color: '#CCC8BC',
              zeroLineColor: '#dadada',
              drawTicks: true,
              tickMarkLength: 3,
              borderDash: [3, 4],
            },
            ticks: {
              fontFamily: 'Montserrat',
              fontSize: 14,
              fontColor: '#908F8D',
            },
          }],
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      consultationsData: types.STATISTICS_CONSULTATIONS_COUNT,
      activeUsersData: types.STATISTICS_ACTIVE_USERS_COUNT,
      labels: types.STATISTICS_CONSULTATIONS_LABELS,
    }),
    chartData() {
      return {
        labels: this.labels,
        datasets: [
          {
            label: 'Обращения / консультации',
            borderColor: '#F2994A',
            backgroundColor: '#F2994A',
            data: this.consultationsData,
            pointHitRadius: 10,
            lineTension: 0,
            fill: false,
          },
          {
            label: 'Пользователи / активные пользователи',
            backgroundColor: '#219653',
            borderColor: '#219653',
            data: this.activeUsersData,
            pointHitRadius: 10,
            lineTension: 0,
            fill: false,
          },
        ],
      };
    },
  },
};
</script>

<style lang='scss' scoped>

</style>
