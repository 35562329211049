<template>
  <div class="w-auto">
    <div class="statistics-consultations">
      <p class="statistics-title">
        Количество обращений и активные пользователи
      </p>

      <chart-statistics-consultations class="w-100" />

      <p class="statistics-info statistics-info-title">
        Итоговые показатели:
      </p>

      <p class="statistics-info">
        Общее количество пользователей:

        <span class="crm-info-value">
          {{ totalActiveUsersCount }}
        </span>
      </p>

      <p class="statistics-info">
        Общее количество обращений:

        <span class="crm-info-value">
          {{ totalConsultationsCount }}
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ChartStatisticsConsultations from './ChartStatisticsConsultations';

export default {
  name: 'StatisticsConsultations',
  components: {
    ChartStatisticsConsultations,
  },
  computed: {
    ...mapGetters({
      totalActiveUsersCount: 'Statistics/TOTAL_ACTIVE_USERS_COUNT',
      totalConsultationsCount: 'Statistics/TOTAL_CONSULTATIONS_COUNT',
    }),
  },
};
</script>

<style lang="scss" scoped>
.statistics-consultations {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 100%;
  box-sizing: border-box;
  background-color: $white;
  border-radius: 10px;
  box-shadow: 0px 1px 2px $gray-shadow;
  font-size: 16px;
  line-height: 20px;
}
.statistics-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: $blue-magenta;
  margin: 0;
}
.statistics-info-title {
  font-weight: 600;
  color: $gray-dark;
}
.statistics-info {
  margin: 0;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}
.crm-info-value {
  margin-left: 15px;
  color: $blue-magenta;
}
</style>
